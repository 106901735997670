import axios from "axios"
import {
  GATSBY_SEND_EMAIL_API_URL,
  GATSBY_SEND_EMAIL_API_KEY,
  GATSBY_ENV,
} from "gatsby-env-variables"

const EMAIL_URL = GATSBY_SEND_EMAIL_API_URL
const API_KEY = GATSBY_SEND_EMAIL_API_KEY

/*
 * Send email service. Send email to submitter, cc maerskcare@medgrocer.com
 */

export async function sendEmail({
  to,
  cc,
  from,
  subject,
  html,
  callback = () => null,
  attachments,
  bcc,
}) {
  const emailBody = { to, from, subject, html, attachments }
  if (cc) emailBody["cc"] = cc
  // if (bcc) emailBody["bcc"] = bcc
  if (GATSBY_ENV !== "production") emailBody["subject"] = `[TEST] ${subject}`
  const response = await axios
    .post(EMAIL_URL, emailBody, {
      headers: { "x-api-key": API_KEY },
    })
    .then(() => {
      callback()
    })
    .catch((error) =>
      console.log("There was an error sending to ", to, ": ", error)
    )
  return response
}
