import React from "react"
import Section from "elements/Section"
import Message from "elements/Message"
import EditDetailsButton from "elements/EditDetailsButton"
import FileThumbnail from "elements/Dropzone/FileThumbnail"

const CompanyIdSummary = ({ ids }) => {
  return (
    <Section
      title="Company ID"
      addOns={{
        right: <EditDetailsButton route="/enrollment#company-id" />,
      }}
    >
      {ids?.length > 0 ? (
        <div className="mx-1">
          <div className="columns is-vcentered is-multiline">
            {ids?.map((id) => (
              <div className="column is-6">
                <FileThumbnail file={id} hasFileName />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Message color="warning">
          <p className="has-text-black is-size-6">
            You have not uploaded any documents.
          </p>
        </Message>
      )}
    </Section>
  )
}

export default CompanyIdSummary
